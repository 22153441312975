import React, { useState, useEffect, useRef } from 'react';

const NavMenu = () => {
  const [activeSection, setActiveSection] = useState();

  const observer = useRef(null);
  useEffect(() => {
//create new instance and pass a callback function
    observer.current = new IntersectionObserver((entries) => {
    const visibleSection = entries.find((entry) => entry.isIntersecting)?.target;
//Update state with the visible section ID
      if (visibleSection) {
        console.log("visibleSection:" ,visibleSection.id )
        setActiveSection(visibleSection.id);
       }
    });

//Get custom attribute data-section from all sections
    const sections = document.querySelectorAll('section');
    sections.forEach((section) => {
      observer.current.observe(section);
    });

//Cleanup function to remove observer
    return () => {
      sections.forEach((section) => {
        observer.current.unobserve(section);
      });
    };
  }, []);
 
  return (
    <>
    <header id="header" className="header fixed-top d-flex align-items-center">
            <div className="container d-flex align-items-center justify-content-between">
                <a href="/" className="logo d-flex align-items-center me-auto me-lg-0">
                    <img src="https://www.ahteco.com/Content/images/AHT_logo.png" alt="" />
                </a>
                <nav id="navbar" className="navbar">
                    <ul>
                        <li><a href="#about" className={activeSection === 'about' ? 'active': null}   name="aboutActive" >About AHT</a></li>
                        <li><a href="#quote" className={activeSection === 'quote' ? 'active': null}  name="quoteActive" >Get a rate quote</a></li>
                        <li><a href="#faqs"  className={activeSection === 'faqs'  ? 'active': null}  name="faqsaActive" >FAQs</a></li>
                        <li><a href="#contact" className={activeSection === 'contact'  ? 'active': null}  name="contactActive" >Contact us</a></li>
                        <li><a href="#placeorder" className={activeSection === 'placeorder' ? 'active': null}   name="placeorderActive">Place an order</a></li>
                     </ul>
                </nav>
                <a className="btn-book-a-table" href="tel:+855.248.4853">855.248.4853</a>
                <i className="mobile-nav-toggle mobile-nav-show bi bi-list" />
                <i className="mobile-nav-toggle mobile-nav-hide d-none bi bi-x" />
            </div>
        </header>
        

    </>
  );
};

export default NavMenu

import React, { Component } from 'react';

export class Footer extends Component {
    static displayName = Footer.name;

    render() {
        return (
            <div>
  {/* ======= Footer ======= */}
  <footer id="footer" className="footer">
    <div className="container">
      <div className="row gy-3">
        <div className="col-lg-4 col-md-6 footer-links d-flex">
          <div>
            <h4>Affiliates</h4>
            <ul>
              <li><a href="https://www.richmondamerican.com" target="_blank" rel="noreferrer">Richmond American Homes</a></li>              
              <li><a href="https://www.homeamericanmortgage.com" target="_blank" rel="noreferrer">Mortgage</a></li>
              <li><a href="https://www.americanhomeinsurance.com" target="_blank" rel="noreferrer">Insurance</a></li>
            </ul>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 footer-links d-flex">
          <div>
            <h4>Additonal resources</h4>
            <ul>
              <li><a href="https://www.richmondamerican.com/career-center" target="_blank" rel="link noreferrer">Careers</a></li>
              <li><a href="https://www.richmondamerican.com/privacy-policy" target="_blank" rel="link noreferrer">Privacy policy and notice of collection of personal information</a></li>
              <li><a href="https://www.richmondamerican.com/ccpa" target="_blank" rel="link noreferrer">Do not sell my information &amp; other requests</a></li>
              <li><a href="https://www.richmondamerican.com/terms-conditions" target="_blank" rel="link noreferrer">Terms &amp; Conditions</a></li>
            </ul>
          </div>
        </div>
        <div className="col-lg-3 col-md-6 footer-links">
          <h4>Follow us on LinkedIn</h4>
          <div className="social-links d-flex">
          <a href="https://www.linkedin.com/company/ahteco" target="_blank" rel="link noreferrer" className="linkedin"><i className="bi bi-linkedin" /></a>
          </div>
        </div>
      </div>
    </div>
    <div className="container">
      <div className="copyright">
        <img src="https://www.ahteco.com/Content/images/equalhousing.png" target="_blank" rel="link noreferrer" alt="Equal housing Opportunity logo" /> Equal Housing Opportunity ©2024 American Home Title &amp; Escrow.
      </div>
      <div className="credits">
        <p>Having trouble viewing this site? Write us at <a href="mailto:AHTquestions@mdch.com">AHTquestions@mdch.com</a></p>
        <p>This information is provided for general informational purposes only and is not intended to be insurance advice. All coverages and endorsements are subject to exclusions, restrictions and limitations. Review your policy with your Insurance Specialist to determine your coverages.</p>
        <p>American Home Title and Escrow Company: <a href="https://www.richmondamerican.com/terms-conditions#AHT" target="_blank" rel="link noreferrer">Click here</a> for licensing information and disclosures. Service available in Colorado, Florida, Nevada, Maryland, and Virginia. Affiliated Businesses: <a href="https://www.richmondamerican.com/terms-conditions#Affiliated" target="_blank" rel=" link noreferrer">Click here</a> for information on our affiliated businesses.</p>
      </div>
    </div>
  </footer>{/* End Footer */}
  {/* End Footer */}
  {/* <a href="#home" className="scroll-top d-flex align-items-center justify-content-center"><i className="bi bi-arrow-up-short" /></a> */}
</div>
        );
    }
}
export default Footer

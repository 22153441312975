import React, { Component } from 'react';
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Reaptcha from 'reaptcha';

export class OrderForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            email: '',
            propertyaddress: '',
            additionalpropertyaddress: '',
            state: '',
            city: '',
            county: '',
            zip: '',
            sellername: '',
            sellerphonenumber: '',
            selleraddress: '',
            selleradditionaladdress: '',
            sellerstate: '',
            sellercity: '',
            sellerzip: '',
            buyername: '',
            buyerphonenumber: '',
            buyeraddress: '',
            buyeradditionaladdress: '',
            buyerstate: '',
            buyercity: '',
            buyerzip: '',
            mortgagecompany: '',
            mortgagecompanyphonenumber: '',
            listingcompany: '',
            listingcompanyphonenumber: '',
            attorneyname: '',
            attorneyphonenumber: '',
            refinanceamount: '',
            purchaseprice: '',
            cashamount: '',
            mortgageamount: '',
            desiredclosingdate: '',
            specialinstructions: '',
            Result: [],
            verified: false
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

        this.baseState = this.state;
    }

    // Submit form 
    handleSubmit(e) {
        e.preventDefault();
        const data = JSON.stringify({
            email: this.state.email,
            propertyaddress: this.state.propertyaddress,
            additionalpropertyaddress: this.state.additionalpropertyaddress,
            state: this.state.state,
            city: this.state.city,
            county: this.state.county,
            zip: this.state.zip,
            sellername: this.state.sellername,
            sellerphonenumber: this.state.sellerphonenumber,
            selleraddress: this.state.selleraddress,
            selleradditionaladdress: this.state.selleradditionaladdress,
            sellerstate: this.state.sellerstate,
            sellercity: this.state.sellercity,
            sellerzip: this.state.sellerzip,
            buyername: this.state.buyername,
            buyerphonenumber: this.state.buyerphonenumber,
            buyeraddress: this.state.buyeraddress,
            buyeradditionaladdress: this.state.buyeradditionaladdress,
            buyerstate: this.state.buyerstate,
            buyercity: this.state.buyercity,
            buyerzip: this.state.buyerzip,
            mortgagecompany: this.state.mortgagecompany,
            mortgagecompanyphonenumber: this.state.mortgagecompanyphonenumber,
            listingcompany: this.state.listingcompany,
            listingcompanyphonenumber: this.state.listingcompanyphonenumber,
            attorneyname: this.state.attorneyname,
            attorneyphonenumber: this.state.attorneyphonenumber,
            refinanceamount: this.state.refinanceamount,
            purchaseprice: this.state.purchaseprice,
            cashamount: this.state.cashamount,
            mortgageamount: this.state.mortgageamount,
            desiredclosingdate: this.state.desiredclosingdate,
            specialinstructions: this.state.specialinstructions
        })
        const config = {
            headers: {
                'content-type': 'application/json',
                'Accept': 'application/json'
            }
        }
        axios
            .post('api/aht/PlaceOrder', data, {
                headers: config.headers
            })
            .then(response => {

                this.ShowToastNotification(response);

            })
            .catch(error => {
                console.log(error)
            })
    }

    handleChange = event => {
        const { value, name } = event.target;
        this.setState({ [name]: value });
    };

    resetForm = () => {
        this.setState(this.baseState)
    }

    onVerify = recaptchaResponse => {       
        this.setState({
            verified: true
          });
    };

    // Show Notifications based  on data
    ShowToastNotification = response => {

        if (response.status === 200) {
            toast.success("Your request has been sent. Thank you!");
            this.resetForm();
        } else {
            toast.error("Unable to send your message. Please try again.");
        }
    };

    render() {
        return (
            <form onSubmit={this.handleSubmit} className="php-email-form p-3 p-md-4">
                <fieldset className="row">
                    <legend>Property information</legend>
                    <div className="row">
                        <div className="col-xl-6 form-group">
                            <input type="email" className="form-control" name="email" id="email" placeholder="Email*" value={this.state.email} onChange={this.handleChange} required />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-6 form-group">
                            <input type="text" className="form-control" name="propertyaddress" id="propertyaddress" placeholder="Property address" value={this.state.propertyaddress} onChange={this.handleChange} required />
                        </div>
                        <div className="col-xl-6 form-group">
                            <input type="text" className="form-control" name="additionalpropertyaddress" id="propertyaddress2" placeholder="Property address 2" value={this.state.additionalpropertyaddress} onChange={this.handleChange} required />
                        </div>
                        <fieldset className="col-xl-6 form-group">
                            <legend className="d-none">Selec state</legend>
                            <select className="form-select" id="State" name="state" value={this.state.state} onChange={this.handleChange} >
                                <option value="Select a state">Select a state</option>
                                <option value="AL">Alabama</option>
                                <option value="AK">Alaska</option>
                                <option value="AR">Arkansas</option>
                                <option value="AZ">Arizona</option>
                                <option value="CA">California</option>
                                <option value="CO">Colorado</option>
                                <option value="CT">Connecticut</option>
                                <option value="DC">District of Columbia</option>
                                <option value="DE">Delaware</option>
                                <option value="FL">Florida</option>
                                <option value="GA">Georgia</option>
                                <option value="HI">Hawaii</option>
                                <option value="IA">Iowa</option>
                                <option value="ID">Idaho</option>
                                <option value="IL">Illinois</option>
                                <option value="IN">Indiana</option>
                                <option value="KS">Kansas</option>
                                <option value="KY">Kentucky</option>
                                <option value="LA">Louisiana</option>
                                <option value="MA">Massachusetts</option>
                                <option value="MD">Maryland</option>
                                <option value="ME">Maine</option>
                                <option value="MI">Michigan</option>
                                <option value="MN">Minnesota</option>
                                <option value="MS">Missouri</option>
                                <option value="MS">Mississippi</option>
                                <option value="MT">Montana</option>
                                <option value="NC">North Carolina</option>
                                <option value="ND">North Dakota</option>
                                <option value="NE">Nebraska</option>
                                <option value="NH">New Hampshire</option>
                                <option value="NJ">New Jersey</option>
                                <option value="NM">New Mexico</option>
                                <option value="NV">Nevada</option>
                                <option value="NY">New York</option>
                                <option value="OH">Ohio</option>
                                <option value="OK">Oklahoma</option>
                                <option value="OR">Oregon</option>
                                <option value="PA">Pennsylvania</option>
                                <option value="RI">Rhode Island</option>
                                <option value="SC">South Carolina</option>
                                <option value="SD">South Dakota</option>
                                <option value="TN">Tennessee</option>
                                <option value="TX">Texas</option>
                                <option value="UT">Utah</option>
                                <option value="VT">Vermont</option>
                                <option value="VA">Virginia</option>
                                <option value="WA">Washington</option>
                                <option value="WI">Wisconsin</option>
                                <option value="WV">West Virginia</option>
                                <option value="WY">Wyoming</option>
                            </select>
                        </fieldset>
                    </div>
                    <div className="row">
                        <div className="col-xl-4 form-group">
                            <input type="text" name="city" className="form-control" id="city" placeholder="City" value={this.state.city} onChange={this.handleChange} required />
                        </div>
                        <div className="col-xl-4 form-group">
                            <input type="text" name="county" className="form-control" id="county" placeholder="County" value={this.state.county} onChange={this.handleChange} required />
                        </div>
                        <div className="col-xl-4 form-group">
                            <input type="text" name="zip" className="form-control" id="zip" placeholder="ZIP*" value={this.state.zip} onChange={this.handleChange} required />
                        </div>
                    </div>
                </fieldset>
                <fieldset className="row">
                    <legend>Seller information</legend>
                    <div className="col-xl-6 form-group">
                        <input type="text" name="sellername" className="form-control" id="sellername" placeholder="Seller name" value={this.state.sellername} onChange={this.handleChange} required />
                    </div>
                    <div className="col-xl-6 form-group">
                        <input type="text" name="sellerphonenumber" className="form-control" id="sellerphonenumber" placeholder="Seller phone number" value={this.state.sellerphonenumber} onChange={this.handleChange} required />
                    </div>
                    <div className="col-xl-6 form-group">
                        <input type="text" className="form-control" name="selleraddress" id="propertyaddress" placeholder="Property address" value={this.state.selleraddress} onChange={this.handleChange} required />
                    </div>
                    <div className="col-xl-6 form-group">
                        <input type="text" className="form-control" name="selleradditionaladdress" id="propertyaddress2" placeholder="Property address 2" value={this.state.selleradditionaladdress} onChange={this.handleChange} required />
                    </div>
                    <fieldset className="col-xl-6 form-group">
                        <legend className="d-none">Select state</legend>
                        <select className="form-select" id="sellerState" name="sellerstate" value={this.state.sellerstate} onChange={this.handleChange}>
                            <option value="Select a state">Select a state</option>
                            <option value="AL">Alabama</option>
                            <option value="AK">Alaska</option>
                            <option value="AR">Arkansas</option>
                            <option value="AZ">Arizona</option>
                            <option value="CA">California</option>
                            <option value="CO">Colorado</option>
                            <option value="CT">Connecticut</option>
                            <option value="DC">District of Columbia</option>
                            <option value="DE">Delaware</option>
                            <option value="FL">Florida</option>
                            <option value="GA">Georgia</option>
                            <option value="HI">Hawaii</option>
                            <option value="IA">Iowa</option>
                            <option value="ID">Idaho</option>
                            <option value="IL">Illinois</option>
                            <option value="IN">Indiana</option>
                            <option value="KS">Kansas</option>
                            <option value="KY">Kentucky</option>
                            <option value="LA">Louisiana</option>
                            <option value="MA">Massachusetts</option>
                            <option value="MD">Maryland</option>
                            <option value="ME">Maine</option>
                            <option value="MI">Michigan</option>
                            <option value="MN">Minnesota</option>
                            <option value="MS">Missouri</option>
                            <option value="MS">Mississippi</option>
                            <option value="MT">Montana</option>
                            <option value="NC">North Carolina</option>
                            <option value="ND">North Dakota</option>
                            <option value="NE">Nebraska</option>
                            <option value="NH">New Hampshire</option>
                            <option value="NJ">New Jersey</option>
                            <option value="NM">New Mexico</option>
                            <option value="NV">Nevada</option>
                            <option value="NY">New York</option>
                            <option value="OH">Ohio</option>
                            <option value="OK">Oklahoma</option>
                            <option value="OR">Oregon</option>
                            <option value="PA">Pennsylvania</option>
                            <option value="RI">Rhode Island</option>
                            <option value="SC">South Carolina</option>
                            <option value="SD">South Dakota</option>
                            <option value="TN">Tennessee</option>
                            <option value="TX">Texas</option>
                            <option value="UT">Utah</option>
                            <option value="VT">Vermont</option>
                            <option value="VA">Virginia</option>
                            <option value="WA">Washington</option>
                            <option value="WI">Wisconsin</option>
                            <option value="WV">West Virginia</option>
                            <option value="WY">Wyoming</option>
                        </select>
                    </fieldset>
                    <div className="row">
                        <div className="col-xl-4 form-group">
                            <input type="text" name="sellercity" className="form-control" id="sellercity" placeholder="Seller city" value={this.state.sellercity} onChange={this.handleChange} required />
                        </div>
                        <div className="col-xl-4 form-group">
                            <input type="text" name="sellerzip" className="form-control" id="sellerzip" placeholder="Seller ZIP*" value={this.state.sellerzip} onChange={this.handleChange} required />
                        </div>
                    </div>
                </fieldset>
                <fieldset className="row">
                    <legend>Buyer information</legend>
                    <div className="col-xl-6 form-group">
                        <input type="text" name="buyername" className="form-control" id="buyername" placeholder="Buyer name" value={this.state.buyername} onChange={this.handleChange} required />
                    </div>
                    <div className="col-xl-6 form-group">
                        <input type="text" name="buyerphonenumber" className="form-control" id="buyerphonenumber" placeholder="Buyer phone number" value={this.state.buyerphonenumber} onChange={this.handleChange} required />
                    </div>
                    <div className="col-xl-6 form-group">
                        <input type="text" className="form-control" name="buyeraddress" id="propertyaddress" placeholder="Property address" value={this.state.buyeraddress} onChange={this.handleChange} required />
                    </div>
                    <div className="col-xl-6 form-group">
                        <input type="text" className="form-control" name="buyeradditionaladdress" id="propertyaddress2" placeholder="Property address 2" value={this.state.buyeradditionaladdress} onChange={this.handleChange} required />
                    </div>
                    <fieldset className="col-xl-6 form-group">
                        <legend className="d-none">Select state</legend>
                        <select className="form-select" id="buyerState" name="buyerstate" value={this.state.buyerstate} onChange={this.handleChange}>
                            <option value="Select a state">Select a state</option>
                            <option value="AL">Alabama</option>
                            <option value="AK">Alaska</option>
                            <option value="AR">Arkansas</option>
                            <option value="AZ">Arizona</option>
                            <option value="CA">California</option>
                            <option value="CO">Colorado</option>
                            <option value="CT">Connecticut</option>
                            <option value="DC">District of Columbia</option>
                            <option value="DE">Delaware</option>
                            <option value="FL">Florida</option>
                            <option value="GA">Georgia</option>
                            <option value="HI">Hawaii</option>
                            <option value="IA">Iowa</option>
                            <option value="ID">Idaho</option>
                            <option value="IL">Illinois</option>
                            <option value="IN">Indiana</option>
                            <option value="KS">Kansas</option>
                            <option value="KY">Kentucky</option>
                            <option value="LA">Louisiana</option>
                            <option value="MA">Massachusetts</option>
                            <option value="MD">Maryland</option>
                            <option value="ME">Maine</option>
                            <option value="MI">Michigan</option>
                            <option value="MN">Minnesota</option>
                            <option value="MS">Missouri</option>
                            <option value="MS">Mississippi</option>
                            <option value="MT">Montana</option>
                            <option value="NC">North Carolina</option>
                            <option value="ND">North Dakota</option>
                            <option value="NE">Nebraska</option>
                            <option value="NH">New Hampshire</option>
                            <option value="NJ">New Jersey</option>
                            <option value="NM">New Mexico</option>
                            <option value="NV">Nevada</option>
                            <option value="NY">New York</option>
                            <option value="OH">Ohio</option>
                            <option value="OK">Oklahoma</option>
                            <option value="OR">Oregon</option>
                            <option value="PA">Pennsylvania</option>
                            <option value="RI">Rhode Island</option>
                            <option value="SC">South Carolina</option>
                            <option value="SD">South Dakota</option>
                            <option value="TN">Tennessee</option>
                            <option value="TX">Texas</option>
                            <option value="UT">Utah</option>
                            <option value="VT">Vermont</option>
                            <option value="VA">Virginia</option>
                            <option value="WA">Washington</option>
                            <option value="WI">Wisconsin</option>
                            <option value="WV">West Virginia</option>
                            <option value="WY">Wyoming</option>
                        </select>
                    </fieldset>
                    <div className="row">
                        <div className="col-xl-4 form-group">
                            <input type="text" name="buyercity" className="form-control" id="buyercity" placeholder="Buyer city" value={this.state.buyercity} onChange={this.handleChange} required />
                        </div>

                        <div className="col-xl-4 form-group">
                            <input type="text" name="buyerzip" className="form-control" id="buyerzip" placeholder="Buyer ZIP*" value={this.state.buyerzip} onChange={this.handleChange} required />
                        </div>
                    </div>
                </fieldset>
                <fieldset className="row">
                    <legend>Professional contacts</legend>
                    <div className="col-xl-6 form-group">
                        <input type="text" name="mortgagecompany" className="form-control" id="mortgagecompany" placeholder="Mortgage company" value={this.state.mortgagecompany} onChange={this.handleChange} required />
                    </div>
                    <div className="col-xl-6 form-group">
                        <input type="text" name="mortgagecompanyphonenumber" className="form-control" id="mortgagephonenumber" placeholder="Mortgage phone number" value={this.state.mortgagecompanyphonenumber} onChange={this.handleChange} required />
                    </div>
                    <div className="col-xl-6 form-group">
                        <input type="text" name="listingcompany" className="form-control" id="listingcompany" placeholder="Listing agent" value={this.state.listingcompany} onChange={this.handleChange} required />
                    </div>
                    <div className="col-xl-6 form-group">
                        <input type="text" name="listingcompanyphonenumber" className="form-control" id="listingcompanyphonenumber" placeholder="Listing agent phone number" value={this.state.listingcompanyphonenumber} onChange={this.handleChange} required />
                    </div>
                    <div className="col-xl-6 form-group">
                        <input type="text" name="attorneyname" className="form-control" id="attorneyname" placeholder="Attorney name" value={this.state.attorneyname} onChange={this.handleChange} required />
                    </div>
                    <div className="col-xl-6 form-group">
                        <input type="text" name="attorneyphonenumber" className="form-control" id="attorneyphonenumber" placeholder="Attorney phone number" value={this.state.attorneyphonenumber} onChange={this.handleChange} required />
                    </div>
                </fieldset>
                <fieldset className="row">
                    <legend>Financial information</legend>
                    <div className="col-xl-6 form-group">
                        <input type="text" name="refinanceamount" className="form-control" id="refinance" placeholder="Refinance amount" value={this.state.refinanceamount} onChange={this.handleChange} required />
                    </div>
                    <div className="col-xl-6 form-group">
                        <input type="text" name="purchaseprice" className="form-control" id="purchaseprice" placeholder="Purchase price" value={this.state.purchaseprice} onChange={this.handleChange} required />
                    </div>
                    <div className="col-xl-6 form-group">
                        <input type="text" name="cashamount" className="form-control" id="cashamount" placeholder="Cash amount" value={this.state.cashamount} onChange={this.handleChange} required />
                    </div>
                    <div className="col-xl-6 form-group">
                        <input type="text" name="mortgageamount" className="form-control" id="mortgageamount" placeholder="Mortgage amounnt" value={this.state.mortgageamount} onChange={this.handleChange} required />
                    </div>
                    <div className="col-xl-6 form-group">
                        <input type="text" name="desiredclosingdate" className="form-control" id="closingdate" placeholder="Desired closing date (mm/dd/yy)" value={this.state.desiredclosingdate} onChange={this.handleChange} required />
                    </div>
                    <div className="form-group">
                        <textarea className="form-control" name="specialinstructions" rows={5} placeholder="Special instructions" value={this.state.specialinstructions} onChange={this.handleChange} required />
                    </div>
                </fieldset>
                {/* <div className="my-3">
                <div id="captchaDiv" />
                <div className="loading">Loading</div>
                <div className="error-message" />
                <div className="sent-message">Your request has been sent. Thank you!</div>
            </div>
             */}

                <Reaptcha sitekey="6LdkeK8cAAAAAKTWvM8uejJ8kR2llzLgPZjkwXfD" onVerify={this.onVerify} />               
                <ToastContainer />           
              
                {this.state.verified === true ? <div><button type="submit" disabled={!this.state.verified}> Submit</button></div> : null }
              
                <p className="mt-6 disclaimer">By submitting the information on this form, you agree that Richmond American, its respective agents and affiliates (collectively "RAH"), may communicate with you using such method(s) of communication as they may select including sending email messages to the account listed above, contacting you at any phone number provided to RAH, and/or the use of any automatic telephone dialing system, text message or email assigned to a paging or cellular service. You may revoke your consent to receiving such communications at any time. We respect your privacy and will use your contact and other information provided in accordance with the terms of our Privacy Policy. Please review our <a href="https://www.richmondamerican.com/privacy-policy" target="_blank">Privacy Policy</a> for more information.        </p>
            </form>
        );
    }
}
export default OrderForm

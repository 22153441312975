import React, { Component } from 'react';
import axios from "axios";
import {ToastContainer ,toast} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Reaptcha from 'reaptcha';

export class RateQuoteForm extends Component {

    constructor(props) {
        super(props);
        this.state = { 
            name: '', 
            phoneNumber: '',
            email:'',
            estimateType:'',
            region:'',
            comments: '',
            Result: [],
            verified: false
        };  
        this.handleChange = this.handleChange.bind(this);      
        this.handleSubmit = this.handleSubmit.bind(this);
        this.baseState = this.state;
    }    
 
     onVerify = recaptchaResponse => {       
        this.setState({
            verified: true
          });
    };

    // Submit form 
    handleSubmit(e) {
        e.preventDefault();       
        const data = JSON.stringify({
            name:this.state.name,
            phoneNumber: this.state.phoneNumber,
            email:this.state.email,
            estimateType:this.state.estimateType,
            region:this.state.region,
            comments:this.state.comments
        })
    
        const config = {
            headers: {
              'content-type': 'application/json',
              'Accept': 'application/json'
            }
        }

          axios
          .post('api/aht/RequestInfo',data,{
            headers: config.headers
          })
              .then(response => {
                  console.log(response,response)
                  this.ShowToastNotification(response);
          })
          .catch(error => {
            console.log(error)
          })
    }

    handleChange = event => {
      const {value,name} = event.target;
      this.setState({[name]: value});  
    };

    resetForm = () => {
        this.setState(this.baseState)
      }
    
    // Show Notifications based  on data
    ShowToastNotification = response => {      
        console.log(response,response)
        if (response.status === 200) {
            toast.success("Your request has been sent. Thank you!");
            this.resetForm();
        } else {
            toast.error("Unable to send your message. Please try again.");
        }
    
    
};
   
    render() {
        return (
            <form onSubmit={this.handleSubmit} className="php-email-form p-3 p-md-4">
                <div className="row">
                    <div className="col-xl-6 form-group">
                        <input type="text" name="name" className="form-control" id="name" placeholder="Name*"  value={this.state.name} onChange={this.handleChange} required />
                    </div>
                    <div className="col-xl-6 form-group">
                        <input type="text" name="phoneNumber" className="form-control" id="name" placeholder="Phone number*" value={this.state.phoneNumber} onChange={this.handleChange} required />
                    </div>
                    <div className="col-xl-6 form-group">
                        <input type="email" className="form-control" name="email" id="email" placeholder="Email*" value={this.state.email} onChange={this.handleChange}  required />
                    </div>
                    <fieldset className="col-xl-6 form-group">
                        <legend className="d-none">Choose an estimate type</legend>
                        <select className="form-select" name="estimateType" value={this.state.estimateType} onChange={this.handleChange}>
                            <option>Choose an estimate type</option>
                            <option>Buyer cost sheet</option>
                            <option>Seller netsheet</option>
                            <option>Title &amp; escrow fee estimate</option>
                        </select>
                    </fieldset>
                    <fieldset className="col-xl-6 form-group">
                        <legend className="d-none">Select a region</legend>
                        <select className="form-select" name="region" value={this.state.region} onChange={this.handleChange}>
                            <option>Select a region</option>
                            <option>Colorado</option>
                            <option>Florida</option>
                            <option>Maryland</option>
                            <option>Nevada</option>
                            <option>Pennsylvania</option>
                            <option>Virginia</option>
                     
                        </select>
                    </fieldset>
                </div>
                <div className="form-group">
                    <textarea className="form-control" name="comments" rows={5} placeholder="Comments" required value={this.state.comments} onChange={this.handleChange} />
                </div>
                {/* <div className="my-3">
                    <div className="loading">Loading</div>
                    <div className="error-message" />
                    <div className="sent-message">Your request has been sent. Thank you!</div>
                </div> */}
                <Reaptcha sitekey="6LdkeK8cAAAAAKTWvM8uejJ8kR2llzLgPZjkwXfD" onVerify={this.onVerify} />
                <ToastContainer />            
                {this.state.verified === true ? <div><button type="submit">Get a rate quote</button></div> : null }
                <p className="mt-6 disclaimer">By submitting the information on this form, you agree that Richmond American, its respective agents and affiliates (collectively "RAH"), may communicate with you using such method(s) of communication as they may select including sending email messages to the account listed above, contacting you at any phone number provided to RAH, and/or the use of any automatic telephone dialing system, text message or email assigned to a paging or cellular service. You may revoke your consent to receiving such communications at any time. We respect your privacy and will use your contact and other information provided in accordance with the terms of our Privacy Policy. Please review our <a href="https://www.richmondamerican.com/privacy-policy" target="_blank">Privacy Policy</a> for more information.
                </p>
            </form>
        );
    }
}
export default RateQuoteForm

import React from 'react';
import { Route, Routes } from 'react-router-dom';
import AppRoutes from './AppRoutes';
import { Layout } from './components/Layout';
import {useLocation } from "react-router-dom";
import { Home } from './components/Home';
export default function App() {
    const location = useLocation();   
    return (
        <Layout>
            <Routes>
                <Route path="/" exact element={<Home location={location}/>} />         
            </Routes>
        </Layout>
    );
}

